import React from "react";
import { graphql } from "gatsby";

import {
  ContainerCta,
  InternalLinkTypes,
  Layout,
  SEO,
  ContainerContent,
  BannerPage,
  ContainerContentFixed,
  DisplayStorey,
  DisplayRange,
  ContentDisclaimer,
} from "../components";
import Img from "gatsby-image";

export default ({ data }) => {
  const facade = data.allFacadesJson.nodes[0];

  return (
    <Layout>
      <SEO title={facade.name} />
      <BannerPage
        title={facade.name}
        subTitle={
          <div>
            <DisplayStorey storey={facade.storey} />
            <DisplayRange range={facade.range} />
          </div>
        }
      />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={facade.imagePath.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>

      <ContainerContent>
        <ContainerContentFixed style={{ textAlign: "center" }}>
          <section style={{ textAlign: "center" }}>
            <ContentDisclaimer />
          </section>
        </ContainerContentFixed>
      </ContainerContent>
      <ContainerCta
        title={"Can't find the facade you like?"}
        subTitle={"Don't worry, we can build one as per your needs"}
        secondaryText={"Customise your own"}
        secondaryLink={InternalLinkTypes.GetStarted}
        primaryLink={InternalLinkTypes.Facades}
        primaryText={"See Other Facades"}
      />
    </Layout>
  );
};

export const query = graphql`
  query($path: String!) {
    allFacadesJson(filter: { slug: { eq: $path } }) {
      nodes {
        name
        storey
        range
        imagePath {
          childImageSharp {
            fluid(maxWidth: 1800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
